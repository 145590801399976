import "./App.scss";

import React, { Component } from "react";
import TTCanvas from "./components/TTCanvas";

// import { Interface } from "./data/data";

class App extends Component {
  state = {};

  modifyState = (props) => {
    this.setState(props);
  };

  render() {
    console.log(
      "%cproudly presented by %cTINYTREE %c®",
      "font-weight: bold; font-size: 20px;color: rgb(100,100,100)",
      "font-weight: bold; font-size: 30px;color: rgb(100,100,100); text-shadow: 3px 3px 10px rgb(0,0,0); font-family: 'Kelpt 1A Medium',Helvetica,Arial,Lucida,sans-serif; font-style:italic",
      "font-weight: bold; font-size: 20px;color: rgb(100,100,100); text-shadow: 3px 3px 10px rgb(0,0,0)"
    );
    console.log("%chttps://tinytree.de", "font-weight: bold; font-size: 12px;");
    // let logo = Interface.filter((option) => option.option === "logo")[0];
    // let backButton = Interface.filter(
    //   (option) => option.option === "backButton"
    // )[0];
    return (
      <React.Fragment>
        {/* <img src={logo.url} alt={logo.altText}/> */}
        {/* <img src={backButton.url} alt={backButton.altText}/> */}
        {/* <div className={"menu-sidebar"}>
          <div className={"logo-container"}>
            <img src={"assets/img/Logo-BDT.png"} alt="BDT Logo" />
          </div>
          <div className={"menu-container"}>
            <ul>
              <li>
                <img src={"assets/img/home-outline.svg"} alt="Icon Home" />
                Start
              </li>
              <li>
                <img src={"assets/img/podium-outline.svg"} alt="Icon Fallbeipiele" />
                Fallbeispiele
                <ul>
                  <li>Produkt 1</li>
                  <li>Bedienpanel XYZa</li>
                  <li>Produkt 3</li>
                </ul>
              </li>
              <li>
                <img src={"assets/img/bulb-outline.svg"} alt="Icon Dienstleistungen" />
                Dienstleistungen
                <ul>
                  <li>Hardwareentwicklung</li>
                  <li>Softwareentwicklung</li>
                  <li>Baugruppen-Fertigung</li>
                </ul>
              </li>
              <li>
                <img src={"assets/img/PEOPLE-outline.svg"} alt="Icon Kontakt" />
                Info / Kontakt
              </li>
            </ul>
          </div>
        </div> */}
        <TTCanvas />
      </React.Fragment>
    );
  }
}

export default App;
