import React, { Component, useState, Suspense, useEffect } from "react";

import { Canvas, useFrame, useThree } from "@react-three/fiber";

import {
  OrbitControls,
  Html,
  useProgress,
  useFBX,
  useGLTF,
} from "@react-three/drei";

import {
  CubeTextureLoader,
  TextureLoader,
  MeshStandardMaterial,
  MeshBasicMaterial,
  RepeatWrapping,
  VideoTexture,
  sRGBEncoding,
  AnimationMixer,
  Mesh,
  LoopPingPong,
} from "three";

import * as easings from "d3-ease";

import { useSpring, a } from "react-spring/three";

import { Scene, Sprites, Models } from "../data/data";

// import {
//   EffectComposer,
//   DepthOfField,
//   Bloom,
// } from "@react-three/postprocessing";

// softShadows();

var envMap, texConcrete, texFloorTiles, texSky;

const cubeTexureloader = new CubeTextureLoader();
// The CubeTextureLoader load method takes an array of urls representing all 6 sides of the cube.
envMap = cubeTexureloader.load([
  "assets/models/envMap/px.jpg",
  "assets/models/envMap/nx.jpg",
  "assets/models/envMap/py.jpg",
  "assets/models/envMap/ny.jpg",
  "assets/models/envMap/pz.jpg",
  "assets/models/envMap/nz.jpg",
]);
envMap.encoding = sRGBEncoding;

const screenVid = document.createElement("video");
screenVid.src = "assets/video/Imagefilm BDT_720p_high.mp4";
screenVid.crossOrigin = "Anonymous";
screenVid.loop = true;
screenVid.muted = true;
screenVid.playsInline = true;
screenVid.play();

const videoTexture = new VideoTexture(screenVid);
videoTexture.encoding = sRGBEncoding;

const textureLoader = new TextureLoader();
texConcrete = textureLoader.load("assets/models/bdt/concrete_seamless.jpg");
texConcrete.repeat.set(5, 5);
texConcrete.wrapS = texConcrete.wrapT = RepeatWrapping;

texFloorTiles = textureLoader.load("assets/models/bdt/concrete_seamless.jpg");
texFloorTiles.repeat.set(5, 5);
texFloorTiles.wrapS = texFloorTiles.wrapT = RepeatWrapping;

texSky = textureLoader.load("assets/models/bdt/sky.jpg");
texSky.repeat.set(2, 2);
texSky.offset.set(0.05, 0.48);
texSky.wrapS = texSky.wrapT = RepeatWrapping;
// texSky.encoding = sRGBEncoding;

const matFloor = new MeshStandardMaterial({
  color: 0x222222,
  map: texConcrete,
  roughness: 0.0,
  bumpMap: texFloorTiles,
  bumpScale: 0.02,
  // roughnessMap: textureNoise,
  // metalnessMap: textureNoise,
  envMap: envMap,
  envMapIntensity: 0.1,
});

const matChrome = new MeshStandardMaterial({
  color: 0x111111,
  roughness: 0.0,
  envMap: envMap,
  envMapIntensity: 1.0,
});

const matBlackPaint = new MeshStandardMaterial({
  color: 0x000000,
  roughness: 0.3,
  envMap: envMap,
  envMapIntensity: 1.0,
});

const matSky = new MeshBasicMaterial({
  color: 0xffffff,
  map: texSky,
});

const matTVScreen = new MeshBasicMaterial({
  color: videoTexture,
  map: videoTexture,
});

function ContactArea(props) {
  const [radioChange, setRadioChange] = useState(false);

  const [submitted, setSubmitted] = useState("not set");

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted("submitted");

    let name = e.target[0].value;
    let type = e.target[1].checked ? e.target[1].value : e.target[2].value;
    let contact = e.target[3].value;

    var postData = new FormData();
    postData.append("name", name);
    postData.append("type", type);
    postData.append("contact", contact);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "mailhandler/mailhandler.php", true);
    xhr.onload = (data) => {
      if (
        data.currentTarget.responseText === "Message has been sent" ||
        data.currentTarget.responseText === "MailHandlerMessage has been sent"
      ) {
        console.log(data.currentTarget.responseText);
        setSubmitted("success");
      } else {
        console.log(data.currentTarget.responseText);
        setSubmitted("error");
      }
    };
    xhr.onreadystatechange = function () {
      console.log(xhr.responseText);
      console.log(xhr.status);
    };
    xhr.send(postData);
  }

  return (
    <div className={"contact-container"}>
      <h1>Kontakt</h1>
      {submitted !== "success" ? (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            id="text"
            name="text"
            placeholder="Name / Firma"
            required
          ></input>
          <p>Wie möchten Sie kontaktiert werden?</p>
          <input
            onChange={() => setRadioChange(false)}
            type="radio"
            id="eMail"
            name="drone"
            value="eMail"
            defaultChecked
          ></input>
          <label htmlFor="eMail">per Email</label>
          <input
            onChange={() => setRadioChange(true)}
            type="radio"
            id="tel"
            name="drone"
            value="Phone"
          ></input>
          <label htmlFor="tel">per Telefon</label>
          <br />
          {!radioChange ? (
            <input
              type="email"
              id="email"
              name="myGeeks"
              placeholder="Email Adresse"
              required
            ></input>
          ) : (
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Telefonnummer"
              pattern="^[0-9-+\s()]*$"
              required
            ></input>
          )}
          {submitted === "error" ? (
            <p>
              Leider ist etwas schiefgegangen. Bitte versuchen Sie es später
              nocheinmal.
            </p>
          ) : (
            ""
          )}
          {submitted === "submitted" ? (
            <p>bitte warten...</p>
          ) : (
            <button type="submit">Absenden</button>
          )}
        </form>
      ) : (
        <p>
          Vielen Dank für ihre Kontakanfrage! Wir werden uns bei Ihnen melden.
        </p>
      )}
    </div>
  );
}

function InfoBox(props) {
  console.log(props);
  return (
    <div className={"infoBoxContainer " + (props.sprite.imageOverlay ? "opaque" : "")}>
      <div
        className={"infoBoxClose"}
        // onClick={() =>
        //   props.parentStateModifier({
        //     infoboxOpen: false,
        //     fade: false,
        //     objectsHidden: false,
        //     enableOrbit: false,
        //     controls: false,
        //     activeModel: undefined,
        //     test: false,
        //     filmOffset: 0,
        //     explode: false,
        //   })
        // }
        onClick={() => props.goBack()}
      >
        <img
          className="CloseButton"
          src="assets/img/close-outline.svg"
          alt="Logo"
        />
      </div>
      <div className={"infoBoxContent"}>
        {props.sprite.video_url ? (
          <div
            style={{ maxWidth: "80%", marginRight: "10%", marginLeft: "10%" }}
          >
            <video autoPlay controls>
              <source
                src={"assets/video/" + props.sprite.video_url}
                type="video/mp4"
              />
            </video>
          </div>
        ) : (
          ""
        )}
        {props.sprite.imageOverlay ? "" :
        <>
        <h2>{props.sprite.name}</h2>
        {props.sprite.beschreibung ? (
          <p>{props.sprite.beschreibung}</p>
        ) : (
          <p>{props.sprite.kurzbeschreibung}</p>
        )}
        </> }

        {props.sprite.image_url ? (
          <div>
            <img
              src={"assets/img/" + props.sprite.image_url}
              alt={props.sprite.name}
            />
          </div>
        ) : (
          ""
        )}
        {props.sprite.image_url_2 ? (
          <div>
            <img
              src={"assets/img/" + props.sprite.image_url_2}
              alt={props.sprite.name}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

function VideoContainer(props) {
  return (
    <div className={"videoContainer"}>
      <div
        className={"videoContainerClose"}
        onClick={() =>
          props.parentStateModifier({
            videoContainerOpen: false,
          })
        }
      >
        <img
          className="CloseButton"
          src="assets/img/close-outline.svg"
          alt="Logo"
        />
      </div>
      <div className={"videoContainerContent"}>
        <video autoPlay width="320" height="240" controls>
          <source src={props.videourl} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

function Sprite(props) {
  const [hovered, setHovered] = useState(false);
  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);
  // const textureLoader = new TextureLoader();
  // let texture = textureLoader.load("assets/img/" + props.sprite.icon_url);
  return (
    <React.Fragment>
      {/* <sprite scale={props.spriteScale}>
        <spriteMaterial
          attach="material"
          map={texture}
          opacity={1}
          sizeAttenuation={false}
        />
      </sprite> */}
      <Html
        position={[
          parseFloat(props.sprite.posX),
          parseFloat(props.sprite.posY),
          parseFloat(props.sprite.posZ),
        ]}
      >
        <div
          className={
            props.sprite.important ? "pulsating-circle-big" : "pulsating-circle"
          }
          onPointerOver={(e) => {
            e.stopPropagation();
            setHovered(true);
            // props.parentStateModifier({
            //   cursor: "pointer",
            // });
          }}
          onPointerOut={() => {
            setHovered(false);
            // props.parentStateModifier({
            //   cursor: "auto",
            // });
          }}
          onClick={() => {
            setHovered(false);
            props.parentStateModifier({
              controls: false,
              helpContainerOpen: false,
            });
            switch (props.sprite.type) {
              case "dolly":
                props.parentStateModifier({
                  enableOrbit: false,
                });
                setTimeout(() => {
                  props.parentStateModifier({
                    cameraPosition: [
                      parseFloat(props.sprite.targetX),
                      parseFloat(props.sprite.targetY),
                      parseFloat(props.sprite.targetZ),
                    ],
                    lookAt: [
                      parseFloat(props.sprite.lookAtX),
                      parseFloat(props.sprite.lookAtY),
                      parseFloat(props.sprite.lookAtZ),
                    ],
                    controls: props.sprite.controls,
                    enableOrbit: false,
                    layer: parseInt(props.sprite.layer) + 1,
                    spriteParent: props.sprite.id,
                  });
                  if (props.sprite.focusDistance) {
                    props.parentStateModifier({
                      focusDistance: props.sprite.focusDistance,
                      dofEnabled: true,
                    });
                  }
                }, 100);
                break;
              case "infobox":
                props.parentStateModifier({
                  activeSprite: props.sprite.id,
                  infoboxOpen: true,
                });
                break;

              default:
                break;
            }
          }}
        >
          <div className={"tooltip-sprite"}>
            <div className={"tooltip-sprite-content"}>{props.sprite.name}</div>
          </div>
        </div>
      </Html>
      {/* <Ring
        args={[0.45, 0.5, 32]}
        position={[
          parseFloat(props.sprite.posX),
          parseFloat(props.sprite.posY),
          parseFloat(props.sprite.posZ),
        ]}
      >
        <meshBasicMaterial attach="material" color="hotpink" />
      </Ring> */}
    </React.Fragment>
  );
}

function StaticModel(props) {
  const [videoControls, setVideoControls] = useState(false);
  const fbx = useFBX(props.path);

  useEffect(() => {
    document.body.style.cursor = videoControls ? "pointer" : "auto";
  }, [videoControls]);

  fbx.children.forEach((mesh, i) => {
    if (mesh.material && mesh.material.name === "tiles_ground") {
      mesh.material = matFloor;
    }
    if (mesh.material && mesh.material.name === "TVScreen") {
      mesh.material = matTVScreen;
    }
    if (mesh.material && mesh.material.name === "skyShader") {
      mesh.material = matSky;
    }
    mesh.material.transparent = true;

    mesh.receiveShadow = true;
    mesh.castShadow = true;
  });

  const springProps = useSpring({
    config: { duration: 1000, easing: easings.easeCubicInOut },
    opacity: props.fade ? 0 : 1,
    onRest: (ya) => {
      if (props.fade) {
        props.parentStateModifier({
          objectsHidden: true,
          controls: props.controls, //TODO:
        });
      }
    },
  });

  useFrame(() => {
    fbx.children.forEach((mesh, i) => {
      if (mesh.material.length) {
        for (let i = 0; i < mesh.material.length; i++) {
          mesh.material[i].opacity = springProps.opacity.value;
        }
      }
      mesh.material.opacity = springProps.opacity.value;
    });
  });

  return (
    <>
      <group>
        <mesh
          castShadow
          receiveShadow
          position={props.position}
          onClick={(e) => {
            e.stopPropagation();
            if (props.model.video) {
              props.parentStateModifier({ videoContainerOpen: true });
            }
          }}
          onPointerOver={(e) => {
            e.stopPropagation();
            if (props.model.video) {
              setVideoControls(true);
            }
          }}
          onPointerOut={(e) => {
            if (props.model.video) {
              setVideoControls(false);
            }
          }}
          // onPointerOut={(e) => {
          //   setVideoControls(false);
          // }}
        >
          <primitive object={fbx} dispose={null} />
          {videoControls ? (
            <Html
              transform
              // distanceFactor={20}
              position={[7.5, -3, 0]}
              style={{
                width: "50px",
              }}
            >
              <img
                src={"assets/img/expand-outline.svg"}
                alt="expand"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  props.parentStateModifier({ videoContainerOpen: true });
                }}
                onPointerOver={(e) => {
                  e.stopPropagation();
                  if (props.model.video) {
                    setVideoControls(true);
                  }
                }}
              />
            </Html>
          ) : (
            ""
          )}
        </mesh>
      </group>
    </>
  );
}

const LoadFBX = (path) => {
  let fbx = useFBX(path);
  return fbx;
};

function DisplayModel(props) {
  let defaultPosition = [
    parseFloat(props.position[0]),
    parseFloat(props.position[1]),
    parseFloat(props.position[2]),
  ];

  const [expand, setExpand] = useState(false);

  const [hovered, setHovered] = useState(null);

  const [hoverBox, setHoverBox] = useState(true);

  const springProps = useSpring({
    config: { duration: 1000, easing: easings.easeCubicInOut },
    rotation: expand && props.model.rotate ? [0, Math.PI, 0] : [0, 0, 0],
    position: props.active
      ? [
          parseFloat(props.model.focusPosX),
          parseFloat(props.model.focusPosY),
          parseFloat(props.model.focusPosZ),
        ]
      : defaultPosition,
    childExplode: props.explode ? 1 : 0,
  });

  const springProps2 = useSpring({
    config: { duration: 500, easing: easings.easeCubicInOut },
    scale: expand ? [1.1, 1.1, 1.1] : props.scale,
  });

  const fbx = LoadFBX(props.path);

  // const fbx = useLoader(FBXLoader, props.path);
  // let explosionHeight = 8;
  fbx.traverse((mesh, i) => {
    if (mesh instanceof Mesh) {
      if (mesh.material && mesh.material.name === "chrome") {
        mesh.material = matChrome;
      }
      if (mesh.material && mesh.material.name === "paint_black") {
        mesh.material = matBlackPaint;
      }
      if (mesh.material && mesh.material.name === "aiStandardSurface3") {
        mesh.material = matBlackPaint;
      }
      if (mesh.material[0]) {
        mesh.material.forEach((material, i) => {
          if (expand) {
            material.emissive.r = 0.2;
            material.emissive.g = 0.2;
            material.emissive.b = 0.2;
          } else {
            material.emissive.r = 0;
            material.emissive.g = 0;
            material.emissive.b = 0;
          }
        });
      } else {
        if (expand) {
          mesh.material.emissive.r = 0.2;
          mesh.material.emissive.g = 0.2;
          mesh.material.emissive.b = 0.2;
        } else {
          mesh.material.emissive.r = 0;
          mesh.material.emissive.g = 0;
          mesh.material.emissive.b = 0;
        }
      }

      if (props.model.explode && props.explode && props.objectsHidden) {
      } else if (props.model.explode && !props.explode) {
        mesh.position.y = 0;
        mesh.position.z = 0;
      }
    }
  });

  const springPropsFade = useSpring({
    config: { duration: 1000, easing: easings.easeCubicInOut },
    opacity: props.fade && !props.active ? 0 : 1,
  });

  useFrame(() => {
    let explosionHeight = 4;
    fbx.traverse((mesh, i) => {
      if (mesh instanceof Mesh) {
        if (mesh.material.length) {
          for (let i = 0; i < mesh.material.length; i++) {
            mesh.material[i].opacity = springPropsFade.opacity.value;
          }
        }
        mesh.material.opacity = springPropsFade.opacity.value;
        if (props.model.explode && props.objectsHidden) {
          switch (mesh.name.substring(0, 2)) {
            case "do":
              mesh.position.y =
                -springProps.childExplode.value *
                (parseInt(mesh.name.substring(mesh.name.length - 2)) *
                  (explosionHeight / fbx.children.length));
              break;
            case "up":
              mesh.position.y =
                springProps.childExplode.value *
                (parseInt(mesh.name.substring(mesh.name.length - 2)) *
                  (explosionHeight / fbx.children.length));
              break;
            case "fr":
              mesh.position.z =
                springProps.childExplode.value *
                (parseInt(mesh.name.substring(mesh.name.length - 2)) *
                  (explosionHeight / fbx.children.length));

              break;
            case "ba":
              mesh.position.z =
                -springProps.childExplode.value *
                (parseInt(mesh.name.substring(mesh.name.length - 2)) *
                  (explosionHeight / fbx.children.length));
              break;

            default:
              mesh.position.y =
                springProps.childExplode.value *
                (-(explosionHeight / 2) +
                  i * (explosionHeight / fbx.children.length));

              break;
          }
        }
      }
    });
  });

  return (
    <>
      <group>
        {props.model.disableMouseOver && props.isolated && props.objectsHidden ? 
        
        <a.mesh
          onClick={(e) => {
            e.stopPropagation();
            if (props.isolated) {
              setExpand(false);
              if (props.model.download) {
                window.open("assets/" + props.model.pdfLink, "_blank");
                return;
              }
              if (props.model.hoverBox) {
                setHoverBox(true);
                return;
              }

              props.parentStateModifier({
                fade: props.model.rotate ? false : true,
                fade: true,
                activeModel: props.id,
                controls: props.model.controls,
              });
              if (!props.model.rotate) {
                props.parentStateModifier({
                  // filmOffset: 10.5,
                  helpContainerOpen: true,
                  cameraPosition: [
                    parseFloat(props.model.focusPosX) + 5,
                    parseFloat(props.model.focusPosY) + 5,
                    parseFloat(props.model.focusPosZ) + 5,
                  ],
                  lookAt: [
                    parseFloat(props.model.focusPosX),
                    parseFloat(props.model.focusPosY),
                    parseFloat(props.model.focusPosZ),
                  ],
                });
              } else {
                props.parentStateModifier({
                  infoboxOpen: true,
                });
              }
            }
          }}
          // castShadow
          position={springProps.position}
          rotation={springProps.rotation}
          scale={springProps2.scale}
        >
          <primitive object={fbx} dispose={null} />
          {props.model.explode && props.isolated && props.objectsHidden ? (
            <Html
              // transform
              // distanceFactor={5}
              position={[1, -0.5, 0]}
              style={{
                width: "50px",
              }}
            >
              <img
                src={
                  props.explode
                    ? "assets/img/combine-outline-black.svg"
                    : "assets/img/expand-outline-black.svg"
                }
                alt="expand"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  props.parentStateModifier({ explode: !props.explode });
                }}
              />
            </Html>
          ) : (
            ""
          )}
          {props.model.hoverBox && props.isolated ? (
            <Html
              // distanceFactor={5}
              position={[0.3, 0.5, 0]}
              style={{
                width: "50px",
              }}
            >
              <div className={"hoverBoxContainer"}>
                <div className={"hoverBoxContent"}>
                  <h3>{props.model.name}</h3>
                  <form
                    method="get"
                    action={"assets/" + props.model.pdfLink}
                    target="blank"
                  >
                    <button type="submit">Download</button>
                  </form>
                </div>
              </div>
            </Html>
          ) : (
            ""
          )}
          {props.model.imageOverlay && props.isolated && props.objectsHidden ? (
            <Html
              // transform
              // distanceFactor={5}
              position={[1, -0.5, 0]}
              style={{
                width: "50px",
              }}
            >
              <img
                src={
                "assets/img/help-outline.svg"
                }
                alt="info"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  props.parentStateModifier({ imageOverlay: props.model.image_url, infoboxOpen: true });
                }}
              />
            </Html>
          ) : (
            ""
          )}
        </a.mesh>

        :
      
        <a.mesh
          onClick={(e) => {
            e.stopPropagation();
            if (props.isolated) {
              setExpand(false);
              if (props.model.download) {
                window.open("assets/" + props.model.pdfLink, "_blank");
                return;
              }
              if (props.model.hoverBox) {
                setHoverBox(true);
                return;
              }

              props.parentStateModifier({
                fade: props.model.rotate ? false : true,
                fade: true,
                activeModel: props.id,
                controls: props.model.controls,
              });
              if (!props.model.rotate) {
                props.parentStateModifier({
                  // filmOffset: 10.5,
                  helpContainerOpen: true,
                  cameraPosition: [
                    parseFloat(props.model.focusPosX) + 5,
                    parseFloat(props.model.focusPosY) + 5,
                    parseFloat(props.model.focusPosZ) + 5,
                  ],
                  lookAt: [
                    parseFloat(props.model.focusPosX),
                    parseFloat(props.model.focusPosY),
                    parseFloat(props.model.focusPosZ),
                  ],
                });
              } else {
                props.parentStateModifier({
                  infoboxOpen: true,
                });
              }
            }
          }}
          onPointerOver={(e) => {
            e.stopPropagation();
            if (
              (!props.objectsHidden && props.isolated) ||
              (props.isolated && props.model.modelParent)
            ) {
              setExpand(true);
              // props.parentStateModifier({
              //   lookAt: [parseFloat(props.position[0]), parseFloat(props.position[1]), parseFloat(props.position[2])]
              // });
            } else if (
              props.objectsHidden &&
              props.isolated &&
              e.object.name !== "Sonstiges" &&
              props.model.hover
            ) {
              e.object.position.y = 0.1;
              setHovered(e.object.name);
            }
          }}
          onPointerOut={(e) => {
            if (
              (!props.objectsHidden && props.isolated) ||
              (props.isolated && props.model.modelParent)
            ) {
              setExpand(false);
            } else if (
              props.objectsHidden &&
              props.isolated &&
              e.object.name !== "Sonstiges" &&
              props.model.hover
            ) {
              e.object.position.y = 0;
              setHovered(null);
            }
          }}
          // castShadow
          position={springProps.position}
          rotation={springProps.rotation}
          scale={springProps2.scale}
        >
          <primitive object={fbx} dispose={null} />
          {props.model.explode && props.isolated && props.objectsHidden ? (
            <Html
              // transform
              // distanceFactor={5}
              position={[1, -0.5, 0]}
              style={{
                width: "50px",
              }}
            >
              <img
                src={
                  props.explode
                    ? "assets/img/combine-outline-black.svg"
                    : "assets/img/expand-outline-black.svg"
                }
                alt="expand"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  props.parentStateModifier({ explode: !props.explode });
                }}
              />
            </Html>
          ) : (
            ""
          )}
          {props.model.hoverBox && props.isolated ? (
            <Html
              // distanceFactor={5}
              position={[0.3, 0.5, 0]}
              style={{
                width: "50px",
              }}
            >
              <div className={"hoverBoxContainer"}>
                <div className={"hoverBoxContent"}>
                  <h3>{props.model.name}</h3>
                  <form
                    method="get"
                    action={"assets/" + props.model.pdfLink}
                    target="blank"
                  >
                    <button type="submit">Download</button>
                  </form>
                </div>
              </div>
            </Html>
          ) : (
            ""
          )}
          {hovered !== null ? (
            <Html
              // transform
              // center
              // distanceFactor={5}
              position={[0, 0, 0]}
              style={{
                width: "50px",
                pointerEvents: "none",
              }}
            >
              <div className={"hoverBoxContainer"}>
                <div className={"hoverBoxContent"}>
                  <h3>{props.model[hovered + "_headline"]}</h3>
                  <p>{props.model[hovered + "_text"]}</p>
                </div>
              </div>
            </Html>
          ) : (
            ""
          )}
        </a.mesh>
}
      </group>
    </>
  );
}

function AnimatedModel(props) {
  let defaultPosition = [
    parseFloat(props.position[0]),
    parseFloat(props.position[1]),
    parseFloat(props.position[2]),
  ];

  const [expand, setExpand] = useState(false);

  const [play, setPlay] = useState(false);

  const [hovered, setHovered] = useState(null);

  const [hoverBox, setHoverBox] = useState(true);

  const springProps = useSpring({
    config: { duration: 1000, easing: easings.easeCubicInOut },
    rotation: expand && props.model.rotate ? [0, Math.PI, 0] : [0, 0, 0],
    position: props.active
      ? [
          parseFloat(props.model.focusPosX),
          parseFloat(props.model.focusPosY) - 1,
          parseFloat(props.model.focusPosZ),
        ]
      : defaultPosition,
    childExplode: props.explode ? 1 : 0,
  });

  const springProps2 = useSpring({
    config: { duration: 500, easing: easings.easeCubicInOut },
    scale: expand
      ? [props.scale[0] * 1.1, props.scale[1] * 1.1, props.scale[2] * 1.1]
      : props.scale,
  });

  // const model = useGLTF(props.path);

  const { scene, animations } = useGLTF(props.path);

  let mixer;

  mixer = new AnimationMixer(scene);

  scene.traverse((mesh, i) => {
    if (mesh instanceof Mesh) {
      if (mesh.material && mesh.material.name === "chrome") {
        mesh.material = matChrome;
      }
      if (mesh.material && mesh.material.name === "paint_black") {
        mesh.material = matBlackPaint;
      }
      if (
        (mesh.material && mesh.material.name === "aiStandardSurface3") ||
        (mesh.material && mesh.material.name === "aiStandardSurface6")
      ) {
        mesh.material = matBlackPaint;
      }
      if (mesh.material && mesh.material.name === "Level 4") {
        mesh.material = matChrome;
      }
      if (mesh.material[0]) {
        mesh.material.forEach((material, i) => {
          if (expand) {
            material.emissive.r = 0.2;
            material.emissive.g = 0.2;
            material.emissive.b = 0.2;
          } else {
            material.emissive.r = 0;
            material.emissive.g = 0;
            material.emissive.b = 0;
          }
        });
      } else {
        if (expand) {
          mesh.material.emissive.r = 0.2;
          mesh.material.emissive.g = 0.2;
          mesh.material.emissive.b = 0.2;
        } else {
          mesh.material.emissive.r = 0;
          mesh.material.emissive.g = 0;
          mesh.material.emissive.b = 0;
        }
      }
    }
  });

  const springPropsFade = useSpring({
    config: { duration: 1000, easing: easings.easeCubicInOut },
    opacity: props.fade && !props.active ? 0 : 1,
  });

  useEffect(() => {
    console.log("Updated");
    animations.forEach((clip) => {
      const action = mixer.clipAction(clip);

      action.setLoop(LoopPingPong);
      if (!play && props.active) {
        // action.stop();
        action.play();
        action.paused = true;
      } else if (play && props.active) {
        action.play();
        action.timeScale = 1;
        action.clampWhenFinished = true;
      } else {
        action.play();
        action.paused = true;
      }
    });
  });

  useFrame((state, delta) => {
    scene.traverse((mesh, i) => {
      if (mesh instanceof Mesh) {
        if (mesh.material.length) {
          for (let i = 0; i < mesh.material.length; i++) {
            mesh.material[i].opacity = springPropsFade.opacity.value;
          }
        }
        mesh.material.opacity = springPropsFade.opacity.value;
      }
    });
    mixer?.update(delta);
  });

  return (
    <>
      <group>
        <a.mesh
          onClick={(e) => {
            if (props.isolated) {
              e.stopPropagation();
              setExpand(false);
              if (props.model.download) {
                window.open("assets/" + props.model.pdfLink, "_blank");
                return;
              }
              if (props.model.hoverBox) {
                setHoverBox(true);
                return;
              }

              props.parentStateModifier({
                fade: props.model.rotate ? false : true,
                fade: true,
                activeModel: props.id,
                controls: props.model.controls,
              });

              if (!props.model.rotate && props.objectsHidden) {
                if (!play) {
                  // props.parentStateModifier({
                  //   enableOrbit: false,
                  // });
                  setTimeout(() => {
                    props.parentStateModifier({
                      cameraPosition: [
                        parseFloat(props.model.focusPosX) + 7,
                        parseFloat(props.model.focusPosY) + 7,
                        parseFloat(props.model.focusPosZ) + 7,
                      ],
                      lookAt: [
                        parseFloat(props.model.focusPosX),
                        parseFloat(props.model.focusPosY),
                        parseFloat(props.model.focusPosZ),
                      ],
                      controls: false,
                      enableOrbit: false,
                    });
                  }, 100);
                  setTimeout(() => {
                    setPlay(!play);
                  }, 100);
                } else {
                  setPlay(!play);
                  props.parentStateModifier({
                    controls: true,
                    enableOrbit: true,
                  });
                }
              }

              if (!props.model.rotate) {
                props.parentStateModifier({
                  // filmOffset: 10.5,
                  helpContainerOpen: true,
                  cameraPosition: [
                    parseFloat(props.model.focusPosX) + 7,
                    parseFloat(props.model.focusPosY) + 7,
                    parseFloat(props.model.focusPosZ) + 7,
                  ],
                  lookAt: [
                    parseFloat(props.model.focusPosX),
                    parseFloat(props.model.focusPosY),
                    parseFloat(props.model.focusPosZ),
                  ],
                });
              } else {
                props.parentStateModifier({
                  infoboxOpen: true,
                });
              }
            }
          }}
          onPointerOver={(e) => {
            e.stopPropagation();
            if (
              (!props.objectsHidden && props.isolated) ||
              (props.isolated && props.model.modelParent)
            ) {
              setExpand(true);
              // props.parentStateModifier({
              //   lookAt: [parseFloat(props.position[0]), parseFloat(props.position[1]), parseFloat(props.position[2])]
              // });
            } else if (
              props.objectsHidden &&
              props.isolated &&
              e.object.name !== "Sonstiges" &&
              props.model.hover
            ) {
              e.object.position.y = 0.1;
              setHovered(e.object.name);
            }
          }}
          onPointerOut={(e) => {
            if (
              (!props.objectsHidden && props.isolated) ||
              (props.isolated && props.model.modelParent)
            ) {
              setExpand(false);
            } else if (
              props.objectsHidden &&
              props.isolated &&
              e.object.name !== "Sonstiges" &&
              props.model.hover
            ) {
              e.object.position.y = 0;
              setHovered(null);
            }
          }}
          // castShadow
          position={springProps.position}
          rotation={springProps.rotation}
          scale={springProps2.scale}
        >
          <primitive object={scene} dispose={null} />
          {props.model.animated && props.isolated && props.objectsHidden ? (
            <Html
              // transform
              // distanceFactor={5}
              position={[0.01, -0.005, 0]}
              style={{
                width: "50px",
              }}
            >
              <img
                src={
                  play
                    ? "assets/img/stop-outline-black.svg"
                    : "assets/img/play-outline-black.svg"
                }
                alt="play pause"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  if (!play) {
                    // props.parentStateModifier({
                    //   enableOrbit: false,
                    // });
                    setTimeout(() => {
                      props.parentStateModifier({
                        cameraPosition: [
                          parseFloat(props.model.focusPosX) + 7,
                          parseFloat(props.model.focusPosY) + 7,
                          parseFloat(props.model.focusPosZ) + 7,
                        ],
                        lookAt: [
                          parseFloat(props.model.focusPosX),
                          parseFloat(props.model.focusPosY),
                          parseFloat(props.model.focusPosZ),
                        ],
                        controls: false,
                        enableOrbit: false,
                      });
                    }, 100);
                    setTimeout(() => {
                      setPlay(!play);
                    }, 100);
                  } else {
                    setPlay(!play);
                    props.parentStateModifier({
                      controls: true,
                      enableOrbit: true,
                    });
                  }
                }}
              />
            </Html>
          ) : (
            ""
          )}
          {props.model.hoverBox && props.isolated ? (
            <Html
              // distanceFactor={5}
              position={[0.3, 0.5, 0]}
              style={{
                width: "50px",
              }}
            >
              <div className={"hoverBoxContainer"}>
                <div className={"hoverBoxContent"}>
                  <h3>{props.model.name}</h3>
                  <form
                    method="get"
                    action={"assets/" + props.model.pdfLink}
                    target="blank"
                  >
                    <button type="submit">Download</button>
                  </form>
                </div>
              </div>
            </Html>
          ) : (
            ""
          )}
          {hovered !== null ? (
            <Html
              // transform
              // center
              // distanceFactor={5}
              position={[0, 0, 0]}
              style={{
                width: "50px",
                pointerEvents: "none",
              }}
            >
              <div className={"hoverBoxContainer"}>
                <div className={"hoverBoxContent"}>
                  <h3>{props.model[hovered + "_headline"]}</h3>
                  <p>{props.model[hovered + "_text"]}</p>
                </div>
              </div>
            </Html>
          ) : (
            ""
          )}
        </a.mesh>
      </group>
    </>
  );
}

function CategoryModel(props) {
  const [expand, setExpand] = useState(false);
  const springProps = useSpring({
    scale: expand ? [1.1, 1.1, 1.1] : [1, 1, 1],
    position: props.active
      ? [
          parseFloat(props.focusPosition[0]),
          parseFloat(props.focusPosition[1]),
          parseFloat(props.focusPosition[2]),
        ]
      : [
          parseFloat(props.position[0]),
          parseFloat(props.position[1]),
          parseFloat(props.position[2]),
        ],
  });

  const fbx = useFBX(props.path);
  fbx.children.forEach((mesh, i) => {
    if (mesh.material[0]) {
      mesh.material.forEach((material, i) => {
        if (expand) {
          material.emissive.r = 0.1;
          material.emissive.g = 0.2;
          material.emissive.b = 0.3;
        } else {
          material.emissive.r = 0;
          material.emissive.g = 0;
          material.emissive.b = 0;
        }
      });
    } else {
      if (expand) {
        mesh.material.emissive.r = 0;
        mesh.material.emissive.g = 0.5;
        mesh.material.emissive.b = 0.3;
      } else {
        mesh.material.emissive.r = 0;
        mesh.material.emissive.g = 0;
        mesh.material.emissive.b = 0;
      }
    }
  });

  const springPropsFade = useSpring({
    opacity: props.fade && !props.active ? 0 : 1,
  });

  useFrame(() => {
    fbx.children.forEach((mesh, i) => {
      if (mesh.material.length) {
        for (let i = 0; i < mesh.material.length; i++) {
          mesh.material[i].opacity = springPropsFade.opacity.value;
        }
      }
      mesh.material.opacity = springPropsFade.opacity.value;
    });
  });

  return (
    <>
      <group>
        <a.mesh
          onClick={() => {
            if (props.isolated) {
              setExpand(false);
              props.parentStateModifier({
                fade: true,
                activeModel: props.id,
                category: true,
                infoboxOpen: false,
                filmOffset: 0,
              });
              setTimeout(() => {
                props.parentStateModifier({
                  test: true,
                });
              }, 500);
            }
          }}
          onPointerOver={(e) => {
            e.stopPropagation();
            if (!props.objectsHidden && props.isolated) {
              setExpand(true);
            }
          }}
          onPointerOut={(e) => {
            setExpand(false);
          }}
          // castShadow
          position={springProps.position}
          scale={springProps.scale}
        >
          <primitive object={fbx} dispose={null} />
        </a.mesh>
      </group>
    </>
  );
}

function CameraMove(props) {
  const springProps = useSpring({
    config: { duration: 1000, easing: easings.easeCubicInOut },
    from: { offset: 0 },
    to: {
      position: props.position,
      lookAt: props.lookAt,
      offset: props.offset,
    },
    onRest: (ya) => {
      if (props.enableOrbit) {
        props.parentStateModifier({ enableOrbit: true });
      }
    },
  });

  useFrame(({ clock, camera, mouse }) => {
    camera.position.x = springProps.position.payload[0].value;
    camera.position.y = springProps.position.payload[1].value;
    camera.position.z = springProps.position.payload[2].value;
    camera.filmOffset = springProps.offset.value;

    camera.lookAt(
      springProps.lookAt.payload[0].value,
      springProps.lookAt.payload[1].value,
      springProps.lookAt.payload[2].value
    );
    camera.updateProjectionMatrix();
  });
  return null;
}

// function CameraMove(props) {
//   // This one makes the camera move in and out

//   const springProps = useSpring({
//     from: { offset: 0 },
//     to: {
//       offset: props.filmOffset,
//       position: props.position,
//       rotation: props.rotation,
//     },
//   });

//   useFrame(({ clock, camera }) => {
//     camera.filmOffset = springProps.offset.value;
//     if (props.menuLayer !== 3) {
//       camera.position.x = springProps.position.payload[0].value;
//       camera.position.y = springProps.position.payload[1].value;
//       camera.position.z = springProps.position.payload[2].value;

//       camera.rotation.x = springProps.rotation.payload[0].value;
//       camera.rotation.y = springProps.rotation.payload[1].value;
//       camera.rotation.z = springProps.rotation.payload[2].value;
//     }
//     if (!props.controls) {
//       camera.position.x = props.position[0];
//       camera.position.y = props.position[1];
//       camera.position.z = props.position[2];

//       camera.updateProjectionMatrix();

//       props.parentStateModifier({ controls: true });
//     }
//     camera.fov = props.fov;

//     // props.filmOffset;
//     camera.updateProjectionMatrix();
//     // camera.position.z = 50 + Math.sin(clock.getElapsedTime()) * 30
//   });
//   return null;
// }

// Loads the skybox texture and applies it to the scene.
// function SkyBox() {
//   const { scene } = useThree();
//   const texture = envMap;

//   // Set the scene background property to the resulting texture.
//   scene.background = texture;
//   return null;
// }

function LoadingBar() {
  const { loaded } = useProgress();

  // console.log(total);

  let percentage = loaded / 94;

  return (
    <Html
      fullscreen
      zIndexRange={[500000000, 0]}
      position={[-30, 4.4, -100]}
      className="loading-container"
    >
      <div
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#185C9C",
        }}
      >
        <img
          style={{
            position: "absolute",
            left: "50px",
            top: "50px",
            width: "250px",
          }}
          src="assets/img/Logo-BDT.png"
          alt="Logo BDT"
        />
        <h1
          style={{
            position: "absolute",
            bottom: "10px",
            left: "50px",
            color: "#DCE7F0",
          }}
        >
          {parseInt(percentage * 100)} %
        </h1>
        <img
          style={{
            position: "absolute",
            bottom: "-50vh",
            right: "-50vw",
            height: "130vh",
            width: "130vw",
          }}
          src="assets/img/bahnen_light_blue.svg"
          alt="Bahnen_Light_Blue"
        />
      </div>
    </Html>
  );
}

function PreLoadFBX(url) {
  const fbx = useFBX(url);
  return null;
}

function PreLoadGLTF(url) {
  const fbx = useGLTF.preload(url);
  return null;
}

function PreloadEverything(props) {
  let models = Models;
  models.forEach((model) => {
    switch (model.url.split(".")[1]) {
      case "fbx":
        PreLoadFBX("assets/models/" + model.url);
        break;
      case "glb":
        PreLoadGLTF("assets/models/" + model.url);
        break;

      default:
        break;
    }
  });
  return null;
}

class App extends Component {
  componentDidMount() {
    let camera = Scene.filter((option) => (option.option = "camera"))[0];

    this.modifyState({
      cameraPosition: [
        parseFloat(camera.posX),
        parseFloat(camera.posY),
        parseFloat(camera.posZ),
      ],
      lookAt: [
        parseFloat(camera.lookAtX),
        parseFloat(camera.lookAtY),
        parseFloat(camera.lookAtZ),
      ],
      cameraHomePos: [
        parseFloat(camera.posX),
        parseFloat(camera.posY),
        parseFloat(camera.posZ),
      ],
      cameraHomeLookAt: [
        parseFloat(camera.lookAtX),
        parseFloat(camera.lookAtY),
        parseFloat(camera.lookAtZ),
      ],
    });
  }

  state = {
    cameraPosition: [100, 100, 0],
    lookAt: [-100, 0, 0],
    controls: false,
    enableOrbit: true,
    spriteScale: [0.04, 0.04, 0.04],
    layer: 0,
    cursor: "auto",
    cameraHomePos: [100, 100, 0],
    cameraHomeLookAt: [-100, 0, 0],
    spriteParent: undefined,
    infoboxOpen: false,
    activeSprite: undefined,
    activeModel: undefined,
    fade: false,
    objectsHidden: false,
    previousState: { spriteParent: undefined, activeSprite: undefined },
    test: false,
    videoContainerOpen: false,
    helpContainerOpen: true,
    filmOffset: 0,
    explode: false,
    focusDistance: "0.035",
    dofEnabled: false,
    category: false,
    mouseDown: false,
    mousePosition: { x: 0, y: 0 },
  };

  modifyState = (props) => {
    this.setState(props);
  };

  goBack = () => {
    if (
      (this.state.infoboxOpen && !this.state.category) ||
      (this.state.category && !this.state.infoboxOpen) ||
      (this.state.objectsHidden && !this.state.infoboxOpen)
    ) {
      let parentSprite = Sprites.filter(
        (sprite) => sprite.id === this.state.spriteParent
      )[0];
      this.setState({
        // cameraPosition: [parentSprite.targetX, parentSprite.targetY, parentSprite.targetZ],
        // lookAt: [parentSprite.lookAtX, parentSprite.lookAtY, parentSprite.lookAtZ],
        infoboxOpen: false,
        fade: false,
        objectsHidden: false,
        enableOrbit: false,
        controls: false,
        activeModel: undefined,
        test: false,
        filmOffset: 0,
        explode: false,
        category: false,
        helpContainerOpen: false,
      });

      if (this.state.spriteParent === "3") {
        setTimeout(() => {
          this.setState({
            cameraPosition: [
              parseFloat(parentSprite.targetX),
              parseFloat(parentSprite.targetY),
              parseFloat(parentSprite.targetZ),
            ],
            lookAt: [
              parseFloat(parentSprite.lookAtX),
              parseFloat(parentSprite.lookAtY),
              parseFloat(parentSprite.lookAtZ),
            ],
            filmOffset: 0,
          });
        }, 100);
      }
    } else if (this.state.infoboxOpen && this.state.category) {
      let parentCategory = Models.filter(
        (sprite) => sprite.id === this.state.activeModel
      );
      this.setState({
        
        infoboxOpen: false,
        enableOrbit: false,
        controls: false,
        activeModel: parentCategory[0].modelParent,
        filmOffset: 0,
        explode: false,
        test: true,
        objectsHidden: false,
        fade: true,
      });
    } else {
      this.setState({
        cameraPosition: this.state.cameraHomePos,
        lookAt: this.state.cameraHomeLookAt,
        controls: true,
        enableOrbit: false,
        position: 0,
        doorRotation: [0, 0, 0],
        layer: 0,
        spriteParent: undefined,
        fade: false,
        objectsHidden: false,
        activeModel: undefined,
        filmOffset: 0,
        focusDistance: 0.035,
        dofEnabled: false,
        test: false,
        infoboxOpen: false,
      });
    }
  };

  render() {
    let layerSprites = Sprites.filter(
      (sprite) =>
        parseInt(sprite.layer) === this.state.layer &&
        sprite.parent === this.state.spriteParent
    );

    let activeSprite = Sprites.filter(
      (sprite) => sprite.id === this.state.activeSprite
    )[0];

    let activeModel = Models.filter(
      (model) => model.id === this.state.activeModel
    )[0];

    let staticModels = Models.filter((model) => model.static);
    let displayModels = Models.filter(
      (model) =>
        !model.static &&
        model.category === undefined &&
        model.modelParent === undefined &&
        !model.animated
    );
    let animatedModels = Models.filter(
      (model) =>
        !model.static &&
        model.category === undefined &&
        model.modelParent === undefined &&
        model.animated
    );
    let categoryModels = Models.filter(
      (model) => !model.static && model.category
    );

    let childrenModels = Models.filter(
      (model) =>
        (!model.static &&
          model.modelParent !== undefined &&
          model.modelParent === this.state.activeModel) ||
        (!model.static &&
          model.modelParent !== undefined &&
          model.id === this.state.activeModel)
    );

    return (
      <React.Fragment>
        <Canvas
          shadows
          // style={{
          //   cursor: this.state.cursor,
          //   // width: "calc(100% - 250px)",
          //   // float: "right",
          // }}
          camera={{
            position: this.state.cameraHomePos,
            rotation: this.state.cameraRotation,
            fov: 35,
          }}
        >
          <fog attach="fog" args={this.state.fogArgs} />
          <color attach="background" args={["#FFFFFF"]} />
          <ambientLight intensity={0.75} position={[0, 10, 0]} />
          {!this.state.enableOrbit ? (
            <CameraMove
              position={this.state.cameraPosition}
              lookAt={this.state.lookAt}
              parentStateModifier={this.modifyState}
              enableOrbit={this.state.controls}
              offset={this.state.filmOffset}
              mouseDown={this.state.mouseDown}
            />
          ) : (
            <OrbitControls
              target={this.state.lookAt}
              enablePan={this.state.layer === 0 ? false : true}
              enableZoom={this.state.layer === 0 ? false : true}
              minDistance={this.state.layer === 0 ? 5 : 0.1}
              maxDistance={150}
              minPolarAngle={this.state.layer === 0 ? Math.PI / 2.05 : 0}
              maxPolarAngle={this.state.layer === 0 ? Math.PI / 2 : Math.PI}
              minAzimuthAngle={this.state.layer === 0 ? Math.PI / 16 : Infinity}
              maxAzimuthAngle={this.state.layer === 0 ? Math.PI / 8 : Infinity}
              screenSpacePanning={this.state.layer === 0 ? false : true}
              rotateSpeed={this.state.layer === 0 ? 0.02 : 1}
            />
          )}
          {/* {this.state.objectsHidden ? ( */}
          <pointLight position={[-23.02, 7.02, 0.02]} intensity={1} />
          {/* ) : ( */}
          <>
            {/* <pointLight position={[0, 10, 10]} intensity={1} 
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}/> */}
            <directionalLight
              castShadow
              position={[15, 20, 100]}
              intensity={1}
              shadow-mapSize-width={1024}
              shadow-mapSize-height={1024}
              shadow-camera-far={150}
              shadow-camera-left={-50}
              shadow-camera-right={50}
              shadow-camera-top={50}
              shadow-camera-bottom={-10}
            />

            {/* <directionalLight
                castShadow
                position={[-100, 100, -100]}
                intensity={1.5}
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-far={750}
                shadow-camera-left={-100}
                shadow-camera-right={150}
                shadow-camera-top={50}
                shadow-camera-bottom={-50}
              />
              <directionalLight
                castShadow
                position={[100, 100, -100]}
                intensity={1.5}
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-far={750}
                shadow-camera-left={-100}
                shadow-camera-right={150}
                shadow-camera-top={50}
                shadow-camera-bottom={-50}
              /> */}
          </>
          {/* )} */}
          {/* <pointLight position={[0, 10, 10]} intensity={10} /> */}
          {/* <SkyBox /> */}

          {/* Load Sprites from JSON */}
          {layerSprites.map((sprite) => {
            return (
              <Sprite
                key={sprite.id}
                sprite={sprite}
                parentStateModifier={this.modifyState}
                spriteScale={this.state.spriteScale}
              />
            );
          })}

          {this.state.objectsHidden
            ? ""
            : staticModels.map((model) => {
                return (
                  <Suspense fallback={null} key={model.id}>
                    <StaticModel
                      path={"assets/models/" + model.url}
                      position={[model.posX, model.posY, model.posZ]}
                      model={model}
                      parentStateModifier={this.modifyState}
                      fade={this.state.fade}
                      controls={this.state.controls}
                    />
                  </Suspense>
                );
              })}

          {displayModels.map((model) => {
            let element =
              this.state.objectsHidden &&
              model.id !== this.state.activeModel ? (
                ""
              ) : (
                <Suspense fallback={null} key={model.id}>
                  <DisplayModel
                    path={"assets/models/" + model.url}
                    position={[model.posX, model.posY, model.posZ]}
                    scale={[1, 1, 1]}
                    parentStateModifier={this.modifyState}
                    objectsHidden={this.state.objectsHidden}
                    isolated={this.state.spriteParent === model.spriteParent}
                    fade={this.state.fade}
                    id={model.id}
                    active={this.state.activeModel === model.id}
                    model={model}
                    explode={this.state.explode}
                    modelActive={this.state.activeModel}
                  />
                </Suspense>
              );

            return element;
          })}

          {animatedModels.map((model) => {
            let element =
              this.state.objectsHidden &&
              model.id !== this.state.activeModel ? (
                ""
              ) : (
                <Suspense fallback={null} key={model.id}>
                  <AnimatedModel
                    path={"assets/models/" + model.url}
                    position={[model.posX, model.posY, model.posZ]}
                    scale={[100, 100, 100]}
                    parentStateModifier={this.modifyState}
                    objectsHidden={this.state.objectsHidden}
                    isolated={this.state.spriteParent === model.spriteParent}
                    fade={this.state.fade}
                    id={model.id}
                    active={this.state.activeModel === model.id}
                    model={model}
                    explode={this.state.explode}
                    modelActive={this.state.activeModel}
                  />
                </Suspense>
              );

            return element;
          })}

          {categoryModels.map((model) => {
            let element =
              this.state.objectsHidden &&
              model.id !== this.state.activeModel ? (
                ""
              ) : (
                <Suspense fallback={null} key={model.id}>
                  <CategoryModel
                    path={"assets/models/" + model.url}
                    position={[model.posX, model.posY, model.posZ]}
                    focusPosition={[
                      model.focusPosX,
                      model.focusPosY,
                      model.focusPosZ,
                    ]}
                    parentStateModifier={this.modifyState}
                    objectsHidden={this.state.objectsHidden}
                    isolated={this.state.spriteParent === model.spriteParent}
                    fade={this.state.fade}
                    id={model.id}
                    active={this.state.activeModel === model.id}
                  />
                </Suspense>
              );

            return element;
          })}
          {childrenModels.map((model) => {
            return (
              <Suspense fallback={null} key={model.id}>
                <DisplayModel
                  path={"assets/models/" + model.url}
                  position={
                    this.state.test
                      ? [model.posX, model.posY, model.posZ]
                      : [3, 7, 5]
                  }
                  scale={this.state.test ? [1, 1, 1] : [0, 0, 0]}
                  parentStateModifier={this.modifyState}
                  objectsHidden={this.state.objectsHidden}
                  isolated={this.state.activeModel === model.modelParent}
                  // fade={this.state.fade}
                  id={model.id}
                  active={this.state.activeModel === model.id}
                  model={model}
                />
              </Suspense>
            );
          })}
          {/* 
          <EffectComposer key={this.state.focusDistance}>
            <DepthOfField
              // focusDistance={0.075}
              focusDistance={this.state.focusDistance}
              focalLength={0.02}
              bokehScale={1.5}
              height={1000}
            />
          </EffectComposer> */}

          <Suspense fallback={<LoadingBar />}>
            <PreloadEverything />
          </Suspense>
        </Canvas>

        <img
          onClick={() => {
            this.setState({
              cameraPosition: this.state.cameraHomePos,
              lookAt: this.state.cameraHomeLookAt,
              controls: true,
              enableOrbit: false,
              position: 0,
              doorRotation: [0, 0, 0],
              layer: 0,
              spriteParent: undefined,
              fade: false,
              objectsHidden: false,
              activeModel: undefined,
              filmOffset: 0,
              focusDistance: 0.035,
              dofEnabled: false,
              test: false,
              infoboxOpen: false,
            });
          }}
          className="HomeButton"
          src="assets/img/home-outline.svg"
          alt="Logo"
        />
        {this.state.spriteParent ? (
          <img
            onClick={() => {
              this.goBack();
            }}
            className="BackButton"
            src="assets/img/back-outline.svg"
            alt="Logo"
          />
        ) : (
          ""
        )}

        {this.state.infoboxOpen ? (
          <InfoBox
            sprite={activeModel}
            parentStateModifier={this.modifyState}
            goBack={this.goBack}
          />
        ) : (
          ""
        )}
        {this.state.videoContainerOpen ? (
          <VideoContainer
            videourl={screenVid.src}
            parentStateModifier={this.modifyState}
          />
        ) : (
          ""
        )}
        {this.state.spriteParent === "2" ? <ContactArea /> : ""}

        <>
          <div
            className={
              this.state.helpContainerOpen
                ? "helpContainer open"
                : "helpContainer"
            }
            style={{
              position: "absolute",
              bottom: "20px",
              left: "20px",
              zIndex: 100000,
            }}
          >
            {this.state.helpContainerOpen ? (
              <>
                {this.state.controls && this.state.objectsHidden ? (
                  <>
                    <h3>3D Interaktion</h3>
                    <p>Scrollen = Zoom</p>
                    <p>Links-Klick + Mausbewegung = Rotieren</p>
                    <p>Rechts-Klick + Mausbewegung = Verschieben</p>
                  </>
                ) : this.state.layer === 0 ? (
                  <>
                    <h3>Willkommen</h3>
                    <p>
                      auf dem virtuellen Messestand der Bavaria Digital Technik
                      GmbH.
                      <br />
                      Zum Navigieren klicken Sie auf einen der pulsierenden
                      Hotspots.
                    </p>
                  </>
                ) : (
                  ""
                )}
                <div
                  className={"helpBoxClose open"}
                  onClick={() => this.setState({ helpContainerOpen: false })}
                >
                  <img
                    className="CloseButton"
                    src="assets/img/close-outline.svg"
                    alt="Logo"
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className={"helpBoxClose"}
                  onClick={() => this.setState({ helpContainerOpen: true })}
                >
                  <img
                    className="HelpButton"
                    src="assets/img/help-outline.svg"
                    alt="Logo"
                  />
                </div>
              </>
            )}
          </div>
        </>

        {activeModel && activeModel.controls ? (
          <>
            <div
              style={{
                position: "absolute",
                bottom: "20px",
                right: "30px",
                color: "#185c9c",
              }}
            >
              <p style={{ margin: "0", fontSize: "2rem" }}>
                {activeModel.name}
              </p>
              {activeModel.descriptionTextVisible ? (
                <p style={{ whiteSpace: "break-spaces" }}>
                  {activeModel.beschreibung}
                </p>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          ""
        )}

        {/* {this.state.objectsHidden ? (
          <div
            className={"infoBoxClose"}
            onClick={() =>
              this.setState({
                fade: false,
                objectsHidden: false,
                enableOrbit: false,
                controls: false,
                activeModel: undefined,
                test: false,
              })
            }
          >
            X<br></br>Close
          </div>
        ) : (
          ""
        )} */}
        {/* <button
          style={{ position: "absolute", bottom: 0, zIndex: "1000000" }}
          onClick={() => {
            // console.log(this.state.spriteParent);
            this.goBack();
          }}
        >
          back
        </button> */}
      </React.Fragment>
    );
  }
}

export default App;
